.bold-label{
    font-weight: bold;
}

.not-valid-input-border {
    border: solid 2px red;
    border-radius: 3px;
}

.line-break-text {
    white-space: pre-line 
}